import React, {Component} from 'react'
import { GenericPageLayout, StoryPageLayout } from '../../components/layouts'
import { withPreview } from '../../components/common'
import { isEmpty } from 'lodash'

class GenericPagePreview extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: null,
            sitemap: [],
            hasGroup: false,
            sitemap: []
        }
    }

    componentDidMount() {
        sessionStorage.setItem('preview', true)
        this.props.getSitemap().then(sitemap => {
            this.setState({
                sitemap
            })
        })
        this.props.getEntry('genericPage', this.props.location.search).then(entry => {
            this.initializeGenericPage(entry)
        })
    }

    componentWillUnmount() {
        sessionStorage.setItem('preview', false)
    } 

    initializeGenericPage(entry) {
        const page = {
            __typename: 'ContentfulPageGeneric',
            ...entry.fields,
            layout: {layout: entry.fields.layout},
            richText: { ...entry.fields.richText },
            heroImage: entry.fields.heroImage?.fields,
            storyBlock: {
                ...entry.fields.storyBlock?.fields,
                backgroundImage: entry.fields.storyBlock?.fields.backgroundImage.fields,
                story: entry.fields.storyBlock?.fields.story.fields
            },
            groups: entry.fields.groups ? entry.fields.groups.map(group => group.fields): []
        }

        this.setState({
            page,
            hasGroup: !isEmpty(page.groups)
        })
    }

    renderPage(page, hasGroup) {
        const site = this.props.getSiteInfo();
        if (page) {
            const storyPage = { ...page, content: page.richText };
            switch (page.layout.layout) {
                case 'Story':
                    return !this.props.loading && page && site ? <StoryPageLayout preview={true} site={site} story={storyPage} sitemap={this.state.sitemap} /> : <></>;
                default:
                    return !this.props.loading && page && site ? <GenericPageLayout preview={true} site={site} page={page} hasGroup={hasGroup} sitemap={this.state.sitemap} /> : <></>;
            }
        }
        return <></>;
    }

    render() {
        const { page, hasGroup } = this.state;
        return this.renderPage(page, hasGroup);
    }
}

export default withPreview(GenericPagePreview)